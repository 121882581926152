<template>
      <div class="projects">
        <MainSection titleM="Projekti" background="projects/jf19.jpg" class="banner"/>
        <ProjectMain/>        
      </div>
  </template>
  
  <script>
  
  import MainSection from "../components/MainSection.vue"
  import Project from "../components/projects/Project.vue"
  import ProjectMain from "../components/projects/ProjectsMain.vue"
  
  export default {
    name: "Projects",
    components: {
      MainSection,
      Project,
      ProjectMain
    }    
  }  
    
  </script>
  
  <style>
  
  .projects{
    width: auto;
  } 
    
  .projects-main{
    display: flex;
  }
  
  
  </style>