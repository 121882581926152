<template>
    <div class="nav">
        <div id="nav-bar" class="inner">
                <ul class="menu__list">
                    <li class = "disapear"><a @click="filter(0)">Localni Komitet</a></li>
                    <li class = "disapear"><a @click="filter(1)">Iternacionala</a></li>
                    <li class = "disapear"><a @click="filter(2)">Događaji</a></li>
                    <li class = "disapear"><a @click="filter(3)">Projekti</a></li>
                    <li class = "disapear"><a @click="filter(4)">Stipendije</a></li>
                    <li class = "disapear"><a @click="filter(5)">Kategorija1</a></li>
                    <li @mouseover="hover = true" class = "fall" id="#Categories"><a class = "fall_btn">Kategorije</a>
                        <ul class = "fall_menu" id = "#fall_menu">
                            <li><a @click="filter(0)">Localni Komitet</a></li>
                            <li><a @click="filter(1)">Iternacionala</a></li>
                            <li><a @click="filter(2)">Događaji</a></li>
                            <li><a @click="filter(3)">Projekti</a></li>
                            <li><a @click="filter(4)">Stipendije</a></li>
                            <li><a @click="filter(5)">Putovanja</a></li>
                        </ul>
                    </li>
                    <li class = "search-box">
                                <input class = "search-bar" placeholder="Pretraži vesti..." type = "text" name = "search" v-model = "search"/>
                                <img src="../../assets/Icons/search.png" class = "search-icon">
                              
                    </li>
                    
                </ul>
        </div>
    </div>
</template>

<script>




export default {
    name: 'NewsNav',
    data(){
      return{
        search: '',
        hover: true
      }
    },
    components: {
       
    },
    methods: {
        filter(value){
                
        }
    }
}
</script>

<style scoped>

.nav {
    background: linear-gradient(180deg, #990008 0%, #D3000B 100%);
}

.inner {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 92%;
}
.menu__list {
    display: flex;
    justify-content: space-around;
    list-style: none;
    width: 100%;
    color: white;
    font-size: 18px;
}
.menu__list a{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 186.19%;
}

.menu__list a:hover{
    color: white;
    border-bottom: 2px solid white;
    transition: 0.2s;
}

.search-box{
    display: flex;
    align-items: center;
    width: 250px;
}

.search-bar{
    height: 60%;
    width:90%;
    background: #FFFFFF;
    border: 5px solid #E52A30;
    border-radius: 30px;
    padding: 2px 12px;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
}
.search-bar:focus{
    outline:none;
}
.search-icon{
    position: relative;
    margin-left: -40px;
    height: 25px;
}
    

.fall{
    display:none;
}




@media screen and (max-width: 1400px) {
    .menu__list a{
        font-size: 20px;
    }
    .search-bar{
        font-size: 15px;
    }
}
@media screen and (max-width: 1100px) {
    
    .search-box{
        display: flex;
        font-size: 20px;

    }
    .search-bar{
        height: 90%;
    }
    .fall_btn {
        color: white;
        padding: 8px 16px;
        border: solid #D3000B 2px;
        border-radius: 3px;
        font-size: 25px;
    }

    .fall {
        position: relative;
        display: inline-block;
    }

    .fall_menu {
        list-style-type:none;
        display: none;
        position: absolute;
        background: linear-gradient(180deg, #990008 0%, #D3000B 100%);
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        padding: 2px;
        width: 200px;
    }

    .fall_menu a {
        float: none;
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
    }

    .fall_menu a:hover {
        background-color: #7a0107;
    }

    .fall:hover .fall_menu {
        display: block;
    }

    .fall:hover .fall_btn {
        background-color: #7a0107;
    }
    .disapear{
        display : none;
    }
}
</style>