<template>
  <div class="body">
    <Nav class="above"/>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
    <ButtonBackToTop />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue"
import Footer from "@/components/Footer.vue"
import ButtonBackToTop from "./components/ButtonBackToTop.vue";

export default {
  name: "App",
  components: {
    Nav,
    ButtonBackToTop,
    Footer
}
}
</script>

<style>

  @font-face {
    font-family: "Roboto";
    src: url("assets/fonts/Roboto-Regular.ttf");
  }

  body, html {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    background-color: #FEFCFD;
  }

  .above{
    
    z-index: 2;
    position: relative;
  }

  .eestec-red{
    background-color: #e52a30;
  }
  .text-white{
    color: #FEFCFD;
  }

</style>