<template>
  <div>
    <div>
      <ContactMainSection titleM="Kontakt" tekst1M="Udruženje studenata elektrotehnike Evrope" tekst2M="Upravni Odbor" background="uo/uo.jpg"/>
    </div>
    <div>
      <img class="ilustracijaKontakt" id="hramSava" src="../assets/ilustrations/hramR.png" alt="hramSava">
      <img class="ilustracijaKontakt" id="crkvaR" src="../assets/ilustrations/crkvaR.png" alt="crkvaR">
      <img class="ilustracijaKontakt" id="kalemegdan1" src="../assets/ilustrations/mesto3b.png" alt="kalemegdan1">
    </div>
    <div class="Chairperson">
        <ContactCard position="Predsednik udruženja - Teodora Antonić" email="chairperson@eestec.etf.rs" phone="+381 63 534617" imageName="uo/chair.jpg"/>
    </div>
    <div class="positionPanel">      
      <ContactCard position="Kontakt osoba - Andreja Đokić" email="cp@eestec.etf.rs" phone="+381 65 9529109 " imageName="uo/cp.jpg"/>
      <ContactCard position="Odnosi sa privredom - Dušan Milivojević" email="cr@eestec.etf.rs" phone="+381 65 5493071 " imageName="uo/fr.jpg"/>
      <ContactCard position="VD Blagajnik - Đorđe Mitić" email="treasurer@eestec.etf.rs" phone="+381 64 0340190 " imageName="uo/tr.jpg"/>
      <ContactCard position="Ljudski resursi - Mina Joksimović" email="hr@eestec.etf.rs" phone="+381 65 2172003" imageName="uo/hr.jpg"/>
      <ContactCard position="Odnosi sa javnošću - Nikolina Soldo" email="pr@eestec.etf.rs" phone="+381 69 2604202" imageName="uo/pr.jpg"/>
      <!-- <ContactCard position="Informacione tehnologije - Časlav Petronić" email="it@eestec.etf.rs" phone="+381 60 0509011" imageName="uo/it.jpg"/> -->
    </div>
  </div>
</template>

<script>

import ContactCard from '../components/contacts/ContactCard.vue'
import ContactMainSection from '../components/contacts/ContactMainSection.vue';

export default {
  name: "Contact", 
  components: {
    ContactCard,
    ContactMainSection
}
}

</script>

<style> 

  .Chairperson {
      margin: 80px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 80px;
  }

  .ilustracijaKontakt{
      position: absolute; 
  }

  #hramSava{
      left: 20%;
      width: 50%;
      max-width: 775px;
      opacity: 0.25;
  }

  #crkvaR{
      margin-top: 45%;
      right: 20%;
      width: 40%;
      max-width: 575px;
      opacity: 0.25;
  }

  #kalemegdan1{
    margin-top: 61.56%;
    left: 5%;
    width:40%;
    max-width: 575px;
    opacity: 0.25;
  }

  .positionPanel {
    margin: 80px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
    max-width: 1300px;
  }

  

</style>