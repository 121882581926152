<template>
    <div class="gaming">
        <MainSection titleM="Gaming corner" background="game-corner.png"/>
        <div class="game-panels">
            <SmallCard title="Igrica memorije" text="Takmiči se u testu brzine i memorije,
                gde je potrebno da što brže i u sa što manje klikova povežete sve kartice sa njihovim
                parom. Da li ste spremni?" 
                more="Igraj" link="/memory-game" imageName="memory-game.png"/>
            <SmallCard title="Svemirski komiteti" text="Prolazi sa svemirskim brodom tvog komiteta kroz
            sve teži i teži talas kometa u cilju prolaska kroz što više lokalnih komiteta. STIŽE USKORO!!!" 
                more="Igraj" link="/space-rocket" imageName="game-corner.png"/>
        </div>
    </div>
</template>

<script>

import MainSection from "../components/MainSection.vue"
import SmallCard from "../components/SmallCard.vue";

export default {
  name: "GamingCorner",
  components: {
    MainSection,
    SmallCard
}
}
</script>

<style>

.game-panels{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px auto;
    gap: 50px;
}

</style>