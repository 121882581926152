<template>
    <div class="chainreaction">
      <div class= "header">
        Chain Reaction: 2 player game
      </div>
      <div class="game">
        <div class="map">
          <table class="table">
            <tbody>
              <tr v-for="row in numRows" :key="row">
                <td v-for="col in numCols" :key="col">
                  <div 
                    @click="handleCellClick(row, col)"
                    class="cell"
                    :id="`cell-${row}-${col}`"
                    >
                    <div  class="ball ball-0" :id="`ball-${row}-${col}`">
                      
                    </div>
                    <!-- Content for each cell -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  
  export default {
    name: "ChainReaction",
    components: {
    },
    data() {
      return {
        numRows: 5, // Number of rows
        numCols: 5, // Number of columns
        grid: [],
        currentPlayer: 1,
        gameGoint: true,
        gameWinner: ""
      };
    },
    created() {
      for (let i=0; i++; i < this.numRows){
        this.grid.push([])
        for (let j=0; j++; j < this.numCols){
          let cellSize = 3
          if (j==0 && i==0 || j==0 && i==this.numRows-1 || j==this.numCols-1 && i==0 || j==this.numCols-1 && i==this.numRows-1)
              cellSize = 1
          else if (j==0 || i==0 || j==this.numCols || i==this.numRows)
            cellSize = 2
          this.grid[i].push({
            cellSize: cellSize,
            balls: 0,
            player: 0
          })
        }
      }
    },
    methods: {
      getCellStyle(row, col) {
        return {
          width: "40px", // Adjust cell width
          height: "40px", // Adjust cell height
          border: "1px solid #000", // Cell border
          backgroundColor: "#fff", // Cell background color
        };
      },
      handleCellClick(row, col) {
      // This function is called when a cell is clicked.

        if (this.gameGoint && (this.isCellEmpty(row, col) || this.isCellOwnedByCurrentPlayer(row, col))) {
          this.ChainReactions(row, col)
          this.CheckWinner()
          this.switchPlayer()
        }
        
      },
      ChainReactions(row, col){
        const cell = this.grid[row][col]
        if (cell.balls == cell.cellSize){
          let reactions = []
          if (row !=0){
            reactions.push(this.grid[row-1][col])
          }
          if (row !=this.numRows-1){
            reactions.push(this.grid[row+1][col])
          }
          if (col !=0){
            reactions.push(this.grid[row][col-1])
          }
          if (col !=this.numCols-1){
            reactions.push(this.grid[row][col+1])
          }

          while (reactions.length > 0){
            
          }

        }
        else{
          cell.balls++
        }
      },
      isCellEmpty(row, col) {
        return !this.grid[row][col].balls
      },
      isCellOwnedByCurrentPlayer(row, col) {
        return this.grid[row][col].player == this.currentPlayer
      },
      switchPlayer() {
      // Switch to the next player.
      this.currentPlayer = this.currentPlayer === 1 ? 2 : 1;
      },
  },

  }
  
  </script>
  <style scoped>

  .map {
    display: flex;
    justify-content: center;
  }
.table {

  }

  .cell {
    width: 80px;
    height: 80px;
    border: 1px solid #400505;
    border-radius: 5px;
    background-color: #deb2b2;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .chainreaction{
    
  }

  .game{
    padding: 50px;
  }

  .header{
    font-size: 40px;
    padding: 20px;
    color: #400505;
    text-align: center;
  }

  .ball{
    border-radius: 50px;
    align-self: center;
  }

  .ball-0{
    display: none;
  }

  .ball-1{
    width: 30%;
    height: 30%;
  }

  .ball-2{
    width: 40%;
    height: 40%;
  }

  .ball-3{
    width: 60%;
    height: 60%;
  }

  .ball-full{
    border: solid 4px rgb(238, 238, 39);
  }

  .player-1{
    background-color: #e52a30;
  }
  .player-2{
    background-color: rgb(74, 150, 213);
  }

  </style>