<template>
  <div class="panel">
    <div class="panelNumber" ref="scrollRef">
      <CountUp
          class="panelNumberText"
          ref="countUp"
          :endVal="count"
          :options="options"
          :delay="delay"
          :autoplay="autoplay"
      />
    </div>
    <div class="panelLabel">{{label}}</div>
  </div>
</template>
<script>
import CountUp from 'vue-countup-v3';

export default {
  name: 'NumberPanel',
  components: {
    CountUp
  },
  props: {
    count: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      observer: null,
      delay: 3000,
      autoplay: false,
      options: {
        useEasing: true
      }
    }
  },
  setup(){
  },
  mounted() {
    this.observer = new IntersectionObserver(
        this.restart,
        {
          root: null,
          rootMargin: '0px 0px 50px',
          threshold: 1,
        }
    );
    this.observer.observe(this.$refs.scrollRef)
  },
  methods: {
    restart(entries, observer) {
      entries.forEach((entry)=>{
        if(entry.isIntersecting) {
          this.$refs.countUp.restart()
          observer.unobserve(entry.target)
        }
      })
    }
  }
}
</script>
<style>

.panel {
  text-align: center;
  margin: 0;
  min-width: 120px;
  width: 120px;
  height: 170px;
}

.panelNumber {
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 10px;
  background-color: #E52A30;
  height: 120px;
}

.panelNumberText {
  font-size: 37px;
  color: white;
  padding-top: 40px;
}

.panelLabel {
  padding-top: 10px;
  font-size: 18px;
  text-align: center;
}
</style>