<template>
    <div class="carousel-container">
        <div class="slider">
            <CarouselItem
                v-for="(item, index) in carouselItems"
                :key="`ci-${index}`"
                class="slide"
                :img="item.img"
                :style="`transform: translateX(${index * 100}%)`"
            ></CarouselItem>
        </div>
    
        <div class="navigate previous" @click="previous">
            <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#FFFFFF">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z"/>
            </svg>
        </div>
    
        <div class="navigate next" @click="next">
            <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#FFFFFF">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z"/>
            </svg>
        </div>
    
        <div class="position">
            <div class="dot dot-active" id="0" @click="jump"></div>
            <div class="dot" id="1" @click="jump"></div>
            <div class="dot" id="2" @click="jump"></div>
        </div>
    </div>
    </template>
    
    <script>
    import CarouselItem from '@/components/CarouselItem.vue';
    
    export default {
        name: 'Carousel',
        components: {
            CarouselItem
        },
        data () {
            return {
                slider: null,
                dots: null,
                current: 0,
                interval: null,
                numSlides: 0,
                carouselItems: [
                    {
                        img: 'mw20.jpg'
                    },
                    {
                        img: 'game-corner.png'
                    },
                    {
                        img: 'mw20.jpg'
                    },
                ]
            }
        },
        mounted () {
            this.slider = document.querySelector(".slider");
            this.numSlides = document.querySelectorAll(".slide").length;
            this.dots = document.querySelectorAll(".dot");
    
            // setInterval koji ce na svakih recimo 8s da pozove 
            // this.goTo na sledeci CarouselItem u nizu % this.numSlides.
            this.resetInterval();
        },
        beforeDestroy () {
            if (this.interval != null) 
                clearInterval(this.interval);
        },
        methods: {
            resetInterval () {            
                if (this.interval != null) 
                    clearInterval(this.interval);
    
                const _this = this;
    
                this.interval = setInterval(() => {
                    _this.goTo((_this.current + 1) % _this.numSlides);
                }, 8000);
            },
            previous () {
                if (this.current == 0)
                    return;
    
                this.goTo(this.current - 1);
                this.resetInterval();
            },
            next () {
                if (this.current >= this.numSlides)
                    return;
    
                this.goTo((this.current + 1) % this.numSlides);
                this.resetInterval();
            },
            goTo (index) {
                // Slide-uje do zeljenog CarouselItem-a.
                if (index < 0 || index >= this.numSlides || index == this.current) 
                    return;
    
                this.dots[this.current].classList.remove("dot-active");
                this.dots[index].classList.add("dot-active");
    
                this.slider.style.transform = `translateX(${index * (-100)}%)`;
    
                this.current = index;
            },
            jump (event) {
                this.goTo(event.target.id);
                this.resetInterval();
            }
        }
    }
    </script>
    
    <style scoped>
        .carousel-container {
            display: flex;
            align-items: center;
        }
    
        .slider {
            width: 100%;
            height: 100vh;
            display: flex;
            transition: transform .5s ease-in-out;
            will-change: transform;
        }
    
        .slide {
            position: absolute;
        }
    
        .navigate {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
    
            width: 50px;
            height: 50px;
    
            border-radius: var(--border-radius);
            border: 1px solid white;
            backdrop-filter: blur(8px);
    
            transition: opacity .2s linear;
            opacity: 0;
        }
    
        .carousel-container:hover .navigate {
            opacity: 1;
        }
        
        .navigate:hover {
            backdrop-filter: blur(8px) brightness(80%);
            cursor: pointer;
        }
    
        .previous {
            left: 10px;
        }
    
        .next {
            /* pronadji uzrok preplitanja */
            right: 10px;
            padding: 0px;
            top:auto;
        }
        
        p {
            color: white;
            font-size: 20px;
            font-weight: bolder;
            text-shadow: 2px 2px 10px #1f1d1b8c;
        }
    
        h1 {
            font-family: 'JFont', sans-serif;
            color: #fff;
            text-shadow: 2px 2px 10px #1f1d1b8c;
            font-size: 48px;
            max-width: 85%;
        }
    
        .position {
            position: absolute;
            display: flex;
            justify-content: center;
            gap: 10px;
            bottom: -4em;
            left: 0;
            right: 0;
        }
    
        .dot {
            border-radius: 100%;
            width: 15px;
            height: 15px;
            backdrop-filter: blur(8px);
            border: var(--border-outline);
            background: red;
            cursor: pointer;
        }
    
        .dot-active {
            background-color:white;
        }
    
        @media only screen and (max-width: 1150px) {
            .navigate {
                opacity: 1;
                bottom: 1em;
            }
        }
    </style>