import {p5Engine} from "../../../views/SpaceRocket.vue"

const PLANET_SIZE = 300

class Planet{
    constructor(pos, image){
        this.pos = pos
        this.image = image
        this.collected = false;
        this.visited = false;

        this.image.resize(PLANET_SIZE, PLANET_SIZE)

        this.vel = p5Engine.createVector(0, 0.5)
    }

    getGlobalEdges() {
        return [p5Engine.createVector(this.pos.x - PLANET_SIZE/2 - 30, this.pos.y - PLANET_SIZE/2 - 30),
        p5Engine.createVector(this.pos.x - PLANET_SIZE/2 - 30, this.pos.y + PLANET_SIZE/2 + 30),
        p5Engine.createVector(this.pos.x + PLANET_SIZE/2 + 30, this.pos.y - PLANET_SIZE/2 - 30),
        p5Engine.createVector(this.pos.x + PLANET_SIZE/2 + 30, this.pos.y + PLANET_SIZE/2 + 30),]
    }

    update(){
        this.pos.add(this.vel.copy().mult(p5Engine.deltaTime/16))
    }

    show(){
        p5Engine.push()
        p5Engine.imageMode(p5Engine.CENTER)
        p5Engine.translate(this.pos)
        p5Engine.image(this.image, -100, -100)
        p5Engine.pop()
    }
}

export {Planet}