import {p5Engine} from "../../../views/SpaceRocket.vue"

class Score{
    constructor(font){
        this.score = 0
        this.weapon = null
        this.scoreFont = font
    }

    addScore(val){
        this.score += val
    }    

    setWeapon(weapon){
        this.weapon = weapon
    }

    show(){
        p5Engine.push()
        p5Engine.fill(255)
        p5Engine.stroke(255)
        p5Engine.textSize(50)
        p5Engine.textFont(this.scoreFont)
        p5Engine.text(this.score, p5Engine.width*0.75, p5Engine.height*0.1)

        if(!this.weapon) return

        p5Engine.noStroke()
        if (this.weapon.numProjectiles == 0) p5Engine.fill("red")
        else if (this.weapon.numProjectiles < this.weapon.maxNumProjectiles * 0.25) p5Engine.fill("orange")
        else if (this.weapon.numProjectiles < this.weapon.maxNumProjectiles * 0.5) p5Engine.fill("yellow")
        p5Engine.rect(p5Engine.width*0.75, p5Engine.height*0.14, 100, 10)
        p5Engine.fill(50)
        p5Engine.rect(p5Engine.width*0.75, p5Engine.height*0.14, 100 * this.weapon.numProjectiles/this.weapon.maxNumProjectiles, 10)

        // p5Engine.text(this.weapon.numProjectiles, p5Engine.width*0.75, p5Engine.height*0.25)

        p5Engine.pop()
    }
}

export {Score}