<template>
    <div class="card-container">
        <div class="picture-container">
            <img v-if="imageName" class="card-picture" :src="require('@/assets/projects/' + imageName + '')" onerror="this.style.display='none'">
        </div>
        <div class="inner">
            <h2 class="title">{{ title }}</h2>
            <p class="text">{{ text }}</p>
            <p class="more">{{ more }}</p>
        </div>
    </div>
</template>
<script>

export default {

    name: "ProjectCard",
    props: {
        title: String,
        text: String,
        more: String,
        link: String,
        imageName: String
    }

}
</script>

<style scoped>

    .card-container {
        background-color: white;
        display: grid;
        place-items: start;
        position: relative;
        width: 360px;
        aspect-ratio: 1.2;
        box-shadow: 0 5px 12px rgb(0 0 0 / 16.1%);
        border-radius: 35px;
        opacity: 0.9;
        transition: transform 0.25s;
    }
    .card-container:hover {
        transform: scale(1.05);
        transition: transform 0.25s;
        cursor: pointer;
    }


    .card-picture{
        width: 100px;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 10px;
        border-radius: 25px;
    }

    .picture-container{
        /* height: 310px; */
        display: flex;
        align-items: center;
    }

    .inner {
        max-width: 88%;
        position: relative;
        height: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        /* position: absolute; */
        top: 0;
        left: 0;
        font-size: 20px;
        font-weight: 600;
        color: black;
        margin: 15px 0px 5px 20px;
        align-self: flex-start;
    }

    .text {
        max-width: 90%;
        font-size: 18px;
        line-height: 1.4;
        margin: 10px 0px;
        margin-left: 20px;
        /* min-height: 200px; */
        align-self: flex-start;
        text-decoration: none;
    }

    .more {
        /* position: absolute; */
        bottom: 0;
        right: 0;
        color: #990008;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 0px;
        align-self: flex-end;
    }

</style>