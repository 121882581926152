import {p5Engine} from "../../../views/SpaceRocket.vue"

class Projectile{
    constructor(pos = p5Engine.createVector(0, 0), vel = p5Engine.createVector(0, -10), size = 8, damage = 1){
        this.pos = pos
        this.size = size
        this.vel = vel
        this.damage = damage
    }

    setPos(pos){
        this.pos = pos
    }

    update(){
        this.pos.add(this.vel.copy().mult(p5Engine.deltaTime/16))
    }

    show(){
        p5Engine.push()
        p5Engine.fill(255, 100, 100)
        p5Engine.noStroke()
        p5Engine.ellipse(this.pos.x, this.pos.y, this.size, this.size)
        p5Engine.pop()
    }
}

export {Projectile}