<template>
    <div class="logo-container">
        <img alt="Project Logo" class="logo-picture" :src="require('@/assets/projects/' + imageName + '')" onerror="this.style.display='none'">
    </div>
</template>
<script>

export default {

    name: "ProjectsLogo",
    props: {
        imageName: String
    }

}
</script>

<style scoped>

    .logo-container {
        background-color: white;
        width: 50px;
        height: 50px;
        margin-bottom: 40px;
        margin-left: 20px;
        margin-right: 20px;
        
    }

    .logo-picture{
        width: 100%;
        height: 100%;
        margin-top: 20px;
        border: 1px solid black;
        border-radius: 100%;
        transition: 100ms ease;
    }

    .logo-picture:hover{
        box-shadow: 10px 5px 5px lightgray;
        border: none;
    }
</style>