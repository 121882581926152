<template>
    <div class="memory-body">
        <div class="div-title">
            <h1 class="memory-title"> EESTEC - Svemirski komiteti</h1>
            <hr>
        </div>

        <div id="memoryGame">
            <div id="p5Canvas" class="div-game">
            </div>
        </div>
    </div>
</template>

<script>
import p5 from "p5"
import {Asteroid} from "../assets/js/space-rocket-js/asteroid.js"
import {CollisionSystem} from "../assets/js/space-rocket-js/collisionSystem.js"
import {ParticleSystem} from "../assets/js/space-rocket-js/particleSystem.js"
// import {Projectile} from "../assets/js/space-rocket-js/projectile.js"
import {Rocket} from "../assets/js/space-rocket-js/rocket.js"
import {Score} from "../assets/js/space-rocket-js/score.js"
import {Weapon} from "../assets/js/space-rocket-js/weapon.js"
import {Planet} from "../assets/js/space-rocket-js/planet.js"

import Font from "../assets/fonts/scoreFont.otf"
import RocketImage from "../assets/space-rocket/rocket.png"
import AsteroidImage1 from "../assets/space-rocket/asteroid1.jpg"
import AsteroidImage2 from "../assets/space-rocket/asteroid1.jpg"
import AsteroidImage3 from "../assets/space-rocket/asteroid1.jpg"
import AsteroidImage4 from "../assets/space-rocket/asteroid1.jpg"
import PlanetImage1 from "../assets/space-rocket/planet1.png"
import PlanetImage2 from "../assets/space-rocket/planet2.png"
import PlanetImage3 from "../assets/space-rocket/planet3.png"


const sketch = (s) => {
    const DIFFICULTY_INCREASE_RATE = 10
    let ASTEROID_SPAWN_RATE_MILLIS = 2000
    let PLANET_SPAWN_RATE_MILLIS = 30000
    let PROJECTILE_RECHARGE_RATE_MILLIS = 1000
    let GLOBAL_TIMER_RATE_MILLIS = 1000
    let RATE_OF_FIRE = 150  // the interval between two projectiles in milliseconds

    let ASTEROID_IMAGE_COUNT = 4
    let PLANET_IMAGE_COUNT = 3

    // let space_img
    let rocket
    let rocket_img
    let weapon
    let asteroids = []
    let planets = []
    let asteroid_images = []
    let planet_images = []
    let collisionSystem
    let score
    let scoreFont
    let system
    let button

    let automaticFireIntervalHandle
    let rechargingProjectilesIntervalHandle
    let spawnAsteroidsIntervalHandle

    s.preload = () => {
        scoreFont = s.loadFont(Font)
        rocket_img = s.loadImage(RocketImage)
        // space_img = s.loadImage("../assets/space-rocket/space1.jpg")
        /* ITEKAKO TREBA NACI SKALABILAN NACIN ZA UCITAVANJE SLIKA */
        for (let i = 0; i < ASTEROID_IMAGE_COUNT; i++) {
            // asteroid_images[i] = s.loadImage(`../assets/space-rocket/asteroid${i + 1}.jpg`)
            switch (i) {
                case 0:
                    asteroid_images[i] = s.loadImage(AsteroidImage1)
                    break;
                case 1:
                    asteroid_images[i] = s.loadImage(AsteroidImage2)
                    break;
                case 2:
                    asteroid_images[i] = s.loadImage(AsteroidImage3)
                    break;
                case 3:
                    asteroid_images[i] = s.loadImage(AsteroidImage4)
                    break;
                default:
                    break;
            }
        }
        for (let i = 0; i < PLANET_IMAGE_COUNT; i++) {
            // planet_images[i] = s.loadImage(`../assets/space-rocket/planet${i + 1}.png`)
            switch (i) {
                case 0:
                    planet_images[i] = s.loadImage(PlanetImage1)
                    break;
                case 1:
                    planet_images[i] = s.loadImage(PlanetImage2)
                    break;
                case 2:
                    planet_images[i] = s.loadImage(PlanetImage3)
                    break;
                default:
                    break;
            }
        }
        score = new Score(scoreFont)
    }


    s.setup = () => {
        s.createCanvas(500, 600, s.WEBGL);
        s.noCursor()

        collisionSystem = new CollisionSystem()
        rocket = new Rocket(rocket_img, collisionSystem, s.createVector(100, 530))
        weapon = new Weapon(rocket)

        score.setWeapon(weapon)

        spawnAsteroidsIntervalHandle = setInterval(spawnAsteroids, ASTEROID_SPAWN_RATE_MILLIS)
        setInterval(spawnPlanets, PLANET_SPAWN_RATE_MILLIS)
        setInterval(globalTimer, GLOBAL_TIMER_RATE_MILLIS)

        system = new ParticleSystem(s.createVector(s.width / 2, 50));
    }


    s.draw = () => {
        if (rocket.isDead) {
            s.push()
            s.cursor()

            s.push()
            s.noStroke()
            s.translate(-s.width / 2, -s.height / 2)
            s.fill(10, 10, 10, 200)
            s.rect(0, 0, s.width, s.height)
            s.pop()

            let gameOver = "GAME OVER"
            let tryAgain = "TRY AGAIN?"
            s.fill(255, 0, 0)
            s.stroke(255)
            s.textSize(50)
            s.textFont(scoreFont)
            s.rectMode(s.CENTER)
            s.text(gameOver, 0, 0, s.textWidth(gameOver) + 20, 50)

            s.translate(0, 60)
            s.fill(255)
            s.text(tryAgain, 0, 0, s.textWidth(tryAgain) + 20, 50)

            button = s.createButton("YES")
            button.position(s.windowWidth / 2 - 40, /*s.windowHeight / 2 + 300*/650)
            button.style("background: none")
            button.style("background-color: rgb(0, 255, 0)") //
            button.style("border: 1px solid rgb(0, 255, 0)") // ove dvije f-je govore o boji dugmeta, mijenjati po potrebi
            button.style("color: white;")
            button.style("outline: none")
            button.style("font-family: 'Montserrat',Arial,'Helvetica Neue',sans-serif")
            button.style("font-weight: 600")
            button.style("font-size: 16px")
            button.style("padding: 5px 10px")
            button.style("border-radius: 10px;")
            button.size(70, 30)
            button.mousePressed(restart)
            s.pop()
            s.noLoop()
            return
        }
        // s.frameRate(60)
        s.background(80)
        s.push()
        s.translate(-s.width / 2, -s.height / 2)

        rocket.update(asteroids, planets)
        weapon.update()

        for (let p in planets) {
            let planet = planets[p]
            if (outOfScreen(planet)) {
                planets.splice(p, 1)   //remove planet out of screen
            }
            planet.update()
            if (planet.visited) {
                score.addScore(50);
                planet.collected = true;
                planet.visited = false;
            }
        }

        for (let a in asteroids) {
            let asteroid = asteroids[a]
            if (outOfScreen(asteroid)) {
                score.addScore(1)
                asteroids.splice(a, 1)   //remove asteroids out of screen
            }
            asteroid.update()

            for (let p in weapon.projectiles) {
                let projectile = weapon.projectiles[p]
                if (collisionSystem.insdidePolygon(projectile.pos, asteroid.getGloalEdges())) {

                    system.addParticles(projectile.pos.copy(),
                        6, asteroid, asteroid.size * 0.1, asteroid.size * 0.2, s.createVector(-1, 1), s.createVector(1, 3))

                    if (asteroid.damage(projectile)) {
                        if (asteroid.size > 30) {
                            let part1 = new Asteroid(asteroid.pos.copy().add(asteroid.size / 2, 0), asteroid.size / 2,
                                s.random(4, 10), s.createVector(0, s.random(0.5, 1.5)), asteroid.rot_speed / 2, asteroid.asteroid_img)

                            let part2 = new Asteroid(asteroid.pos.copy().add(-asteroid.size / 2, 0), asteroid.size / 2,
                                s.random(4, 10), s.createVector(0, s.random(0.5, 1.5)), -asteroid.rot_speed / 2, asteroid.asteroid_img)

                            asteroids.push(part1)
                            asteroids.push(part2)
                        } else {
                            system.addParticles(projectile.pos.copy().add(0, s.random(0, -5)),
                                6, asteroid, asteroid.size * 0.4, asteroid.size * 0.6, s.createVector(-3, 3), s.createVector(-3, 3))
                        }

                        score.addScore(s.int(asteroid.size / 4))
                        asteroids.splice(a, 1)  //destroy asteroid, or if it's giant, split it

                    }
                    weapon.projectiles.splice(p, 1)
                }
            }
        }

        planets.forEach(planet => {
            planet.show()
        });
        asteroids.forEach(asteroid => {
            asteroid.show()
        });

        weapon.show()
        rocket.show()
        score.show()
        system.show();
        s.pop()
    }

    s.mouseMoved = () => {
        if (!rocket) return;

        let valX, valY
        if (s.mouseX <= s.width - (rocket.width / 2 + 5) && s.mouseX >= (rocket.width / 2 + 5)) valX = s.mouseX;
        else if (s.mouseX > s.width - (rocket.width / 2 + 5)) valX = s.width - (rocket.width / 2 + 5);
        else if (s.mouseX < (rocket.width / 2 + 5)) valX = rocket.width / 2 + 5;

        if (s.mouseY <= s.height - (rocket.height / 2 + 5) && s.mouseY >= (rocket.height / 2 + 5)) valY = s.mouseY;
        else if (s.mouseY > s.height - (rocket.height / 2 + 5)) valY = s.height - (rocket.height / 2 + 5);
        else if (s.mouseY < (rocket.height / 2 + 5)) valY = rocket.height / 2 + 5;
        rocket.move(valX, valY);
    }

    s.mouseDragged = () => {
        s.mouseMoved();
    }

    s.mousePressed = () => {
        weapon.fire();
        clearInterval(rechargingProjectilesIntervalHandle)
        automaticFireIntervalHandle = setInterval(fire, RATE_OF_FIRE)
    }

    s.mouseReleased = () => {
        clearInterval(automaticFireIntervalHandle)
        rechargingProjectilesIntervalHandle = setInterval(recharge, PROJECTILE_RECHARGE_RATE_MILLIS)
    }
    
    function spawnAsteroids() {
        let ran = s.int(s.random(0, ASTEROID_IMAGE_COUNT));
        asteroids.push(new Asteroid(s.createVector(s.random(30, s.width - 30), -50), s.random(20, 60), s.random(4, 10), s.createVector(0, s.random(1, 3)), s.random(-.05, .05), asteroid_images[ran]))
    }

    function spawnPlanets() {
        let ran = s.int(s.random(0, PLANET_IMAGE_COUNT));
        planets.push(new Planet(s.createVector(s.random(s.width), -50), planet_images[ran]))
    }

    function outOfScreen(asteroid) {
        return asteroid.pos.x < -200 || asteroid.pos.x > s.width + 200 || asteroid.pos.y < -250 || asteroid.pos.y > s.height + 250
    }

    let diffculty_cnt = 0
    let asteroid_frequency_cnt = 0

    // adds score and increases difficulty over time
    function globalTimer() {
        score.addScore(1)
        diffculty_cnt++
        asteroid_frequency_cnt++
        if (diffculty_cnt == DIFFICULTY_INCREASE_RATE) {
            Asteroid.increaseDifficulty(1)
            diffculty_cnt = 0
        }
        if (asteroid_frequency_cnt == DIFFICULTY_INCREASE_RATE && ASTEROID_SPAWN_RATE_MILLIS > 300) {
            clearInterval(spawnAsteroidsIntervalHandle)
            ASTEROID_SPAWN_RATE_MILLIS -= 50
            spawnAsteroidsIntervalHandle = setInterval(spawnAsteroids, ASTEROID_SPAWN_RATE_MILLIS)
            asteroid_frequency_cnt = 0
        }
    }

    function fire() {
        weapon.fire();
    }

    function recharge() {
        weapon.recharge();
    }

    function restart() {
        window.location.reload()
    }

    s.windowResized = () => {
        button.position(s.windowWidth / 2 - 40, /*s.windowHeight / 2 + 300*/650)
    }
};


let p5Engine;
export default {
    name: "SpaceRocket",

    beforeMount() {
        p5Engine = new p5(sketch, "p5Canvas");
    },

    mounted() {
        setTimeout(() => {
            //   p5Engine.background("red");
        }, 1000);
    },
}

export { p5Engine };

</script>

<style>

.div-game {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6vh;
}

</style>