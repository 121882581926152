<template>
    <div>
      <MainSection titleM="VESTI" background="mw20.jpg"/>
      <NewsNav class = "newsnav"/>
      <h1 class="vesti">Nove vesti stižu uskoro!</h1>
      <!-- <AllNews/> -->
    </div>
</template>

<script>

import Carousel from '@/components/Carousel.vue'
import NewsNav from "@/components/news/NewsNav.vue"
import MainSection from "../components/MainSection.vue";
import AllNews from "../components/AllNews.vue";

export default {
  name: "News",
  components: {
    NewsNav,
    MainSection,
    AllNews,
    Carousel
  } 
}

</script>
<style scoped>
h1{
  color: rgb(47, 43, 43);
  text-align: center;
  height: 200px;
}
</style>