<template>
    <div class="projects-section">
        <p id="projectsTitle">Projekti</p>
        <div class="projectsPanels">            
            <ProjectCard v-for="(pro, index) in projects" 
                :key="index" 
                :imageName="pro.image" 
                :title="pro.title" 
                :text="pro.text"
                @click="displayComponent(index)">
            </ProjectCard>
        </div>
        <div class="projects-main">
            <div class="sidebar">
                <div id="sidebar_image_container">
                <!---    <img alt="Project Logo" id="sidebar_image" :src="require('@/assets/back_arrow.png')" @click="removeSidebar()"> -->
                </div>
                <div class="projects-logos">
                    <ProjectsLogo v-for="(pro, index) in projects"
                    :key="index" 
                    :imageName="pro.logo"
                    @click="changeComponent(index)">
                    </ProjectsLogo>
                </div>
            </div>
          <div>
            <Project title="JobFair – Kreiraj svoju budućnost!" class="project-field"
                text1="Sajam poslova i praksi - Jobfair se organizuje već 18 godina sa ciljem da spoje studente tehničko-tehnoloških i prirodno-matematičkih fakulteta sa kompanijama. Sajam se održava svakog prvog ponedeljka i utorka u novembru u Zgradi tehničkih fakulteta, a od 2022. godine Sajam se proširio i na Mašinski fakultet. Studenti imaju priliku da se tokom JobFair-a upoznaju sa kompanijama i potencijalno pronađu svoj prvi posao ili praksu! Takođe, studenti imaju mogućnost da ostave svoj CV u JobFair CV bazi, kojoj kompanije učesnice JobFair-a imaju pristup naredne dve godine." 
                text2="Osim samih prilika za zapošljavanje, studenti mogu i da prošire svoja znanja kroz besplatne seminare i radionice - LevelUp, koje se organizuju pred sam Sajam. Kroz radionicu “Razgovor za posao” studenti se upoznaju sa procesom pronalaženja posla, a takođe mogu videti kako izgleda sam intervju za zapošljavanje. “Tech Talk” je radionica na kojoj kompanije učesnice sajma, na danima sajma predstavljaju trenutne inovacije u industriji."
                background1="jf19.jpg" background2="jfZlatni.jpg" background3="jfOrganizacija.jpg" link="www.jobfair.rs"/>
  
            <Project title="EESTech Challenge" class="project-field"
                text1="EESTech Challange je takmičenje internacionalnog karaktera nastalo 2017. godine, namenjeno studentima. Takmičenje se održava u dva nivoa - lokalna runda i finalna runda. U okviru lokalne runde održavaju se hakaton i seminar na različite teme iz oblasti elektrotehnike i računarstva sa ciljem da učesnici nauče nešto novo iz prethodno pomenutih oblasti, kao i da napreduju na profesionalnom i ličnom nivou." 
                text2="Pobednici lokalnih rundi prolaze u finalnu rundu koja se svake godine održava u drugom gradu, jednom od 43 lokalna komiteta Udruženja, a temu hackatona smišljaju domaćini te godine.  2021. Godine Lokalni Komitet Beograd je organizovao finalnu rundu na temu Cybersecurity."
                background1="ec1.jpg" background2="ec2.jpg" background3="ec3.jpg"/>
  
            <Project title="Brand New Engineers" class="project-field"
                text1="“Brand New Engineers - Od integrala do inženjera” je projekat koji Istek LK Beograd organizuje od 2010. Godine, namenjen je studentima i sastoji se iz hakatona i konferencije. Konferenciju čine predavanja iz oblasti elektrotehnike i računarstva koja drže profesori sa Elektrotehničkog fakulteta, kao i predstavnici vodećih kompanija u Srbiji. Teme predavanja koncipirane su tako da učesnike upute u aktuelne trendove inženjerske branše i odgovore na pitanja vezana za karijeru nakon završenih studija." 
                text2="Hakaton se održava u saradnji sa kompanijama i predstavlja takmičenje iz oblasti robotike, logike i kodovanja u trajanju od 24h. Cilj takmičenja jeste da učesnici samostalno istražuju probleme u polju tehnike i elektrotehnike i da ih timskim radom uspešno rešavaju. Od 2022. godine se organizuje i hakaton iz oblasti Blockchain tehnologija. " 
                background1="bne1.jpg" background2="bne2.jpg" background3="bne3.jpg"/>

            <Project title="Soft Skills Academy" class="project-field"
                text1="„Soft Skills Academy – Unapredi svoje sposobnosti!“ predstavlja skup interaktivnih radionica čiji je cilj unapređivanje mekih veština kod studenata. Kako na fakultetu uče i usavršavaju se samo na profesionalnom nivou, Akademija je tu da pokaže koliko je bitno da student zna kako se radi u timu, daje feedback ili kako se najefikasnije rešavaju problemi koji se nađu pred njima. Treninge drže sertifikovani Istek treneri i predstavnici vodećih kompanija u regionu." 
                text2="Akademija mekih veština se prvi put održala upravo u Beogradu, 2010. godine i do danas se organizovala u više od 20 država širom Evrope! Učešće na Akademiji je besplatno i na istu su pozvani svi zainteresovani studenti!"
                background1="ssa3.jpg" background2="ssa2.jpg" background3="ssa1.jpg"/>
  
            <Project title="Kongres" class="project-field"
                text1="Kongres predstavlja najveću međunarodnu konferenciju Udruženja studenata elektrotehnike Evrope i veoma je važan za održavanje i razvoj Udruženja na internacionalnom nivou. Održava se dva puta godišnje (na proleće i jesen), traje sedam dana i u njegovu realizaciju je uključeno više stotina ljudi. Do sada je organizovan 42 puta širom Evrope. Za vreme Kongresa održavaju se sednice, na kojima se govori o budućnosti i napretku samog Udruženja." 
                text2="Istek LK Beograd je organizovao Kongres čak pet puta do sada (2004, 2009, dva puta 2016. i 2022. godine). Imao je tu čast da organizuje jubilarni 30. Kongres u aprilu 2016. i te godine je proglašen za najuspešniji i najorganizovaniji komitet, pored 43 komiteta u 23 države Evrope."
                background1="kongres2.jpg" background2="kongres3.jpg" background3="kongres1.jpg"/>
          </div>
        </div>
    </div>
</template>

<script>

import ProjectCard from "../ProjectCard.vue"
import Project from "./Project.vue"
import ProjectsLogo from "./ProjectsLogo.vue"

export default {
  name: "ProjectMain",
  components: {
    ProjectCard,
    Project,
    ProjectsLogo
  },

  data(){
    return{
        projects: [
                {id:"jobfair", image:"jf-logo.jpg", title:"JobFair - Kreiraj svoju buducnost!", text:"Sajam poslova i praksi - Jobfair se organizuje već 18 godina sa ciljem da spoje studente tehničko-tehnoloških i prirodno-matematičkih fakulteta sa kompanijama.", logo:"jf-logo.jpg"},
                {id:"eestech_challenge", image:"ec-logo.png", title:"EESTech Challenge", text:"EESTech Challange je takmičenje internacionalnog karaktera nastalo 2017. godine, namenjeno studentima. Takmičenje se održava u dva nivoa - lokalna runda i finalna runda.",logo:"ec-logo.png"},
                { id: "brand_new_engineers", image: "bne-logo.png", title: "Brand New Engineers", text: "“Brand New Engineers - Od integrala do inženjera” je projekat koji Istek LK Beograd organizuje od 2010. Godine, namenjen je studentima i sastoji se iz hakatona i konferencije.", logo: "bne-logo.png" },
                {id:"soft_skills_academy", image:"ssa-logo.png", title:"Soft Skills Academy", text:"„Soft Skills Academy – Unapredi svoje sposobnosti!“ predstavlja skup interaktivnih radionica čiji je cilj unapređivanje mekih veština kod studenata.",logo:"ssa-logo.png"},
                {id:"kongres", image:"kongres-logo.png", title:"Kongres", text:"Kongres predstavlja najveću međunarodnu konferenciju Udruženja studenata elektrotehnike Evrope i veoma je važan za održavanje i razvoj Udruženja na internacionalnom nivou.",logo:"kongres-logo.png"}  
        ],
        sidebar_on: 1
    }
  },

  methods: {
     changeComponent (n) {
        
        const components_for_display = document.getElementsByClassName("project-field")
        for (let i=0;i<components_for_display.length;i++){
            components_for_display[i].style.display="none"
        }
         components_for_display[n].style.display = "flex"


    },
    displayComponent (n) {
        const sidebar=document.getElementsByClassName("sidebar")
        sidebar[0].style.display="block"
        
        const components_for_display = document.getElementsByClassName("project-field")
        components_for_display[n].style.display = "flex"

        const main = document.getElementsByClassName("projectsPanels")
        main[0].style.display = "none"

        const title = document.getElementById("projectsTitle")
        title.style.display = "none"

    },
    removeSidebar(){
        if(this.sidebar_on){
        const sidebar=document.getElementsByClassName("sidebar")
        sidebar[0].style.borderRightStyle="none"
        const image=document.getElementById("sidebar_image")
        image.style.transform="rotate(-180deg)"
        const logos=document.getElementsByClassName("projects-logos")
        logos[0].style.display="none"
        sidebar[0].style.display="none"
        this.sidebar_on=0

        }
        else{
            const sidebar=document.getElementsByClassName("sidebar")
            sidebar[0].style.borderRightStyle="solid"
            const image=document.getElementById("sidebar_image")
            image.style.transform="rotate(0deg)"
            const logos=document.getElementsByClassName("projects-logos")
            logos[0].style.display="block"
            this.sidebar_on=1
        }
    }
  }
}
</script>

<style>

li
{
    list-style-type: none;
}

.projects-section{
    padding-top: 40px;
    position: relative;
    background-color: white;
    padding-bottom: 100px;
}
  
.projects-main{
    width: auto;
    display: flex;
}

.sidebar{
    border-right: 2px solid black;
    width: 150px auto;
    display: none;
}

#sidebar_image_container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 50px;
    margin-left: 20px;
    margin-right: 20px;
        
}
#sidebar_image{
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
}


#sidebar_image:hover{
     border: 2px solid #FA4952;
}

.projects-logos{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    position: sticky;
    top: 20%;
    bottom: 25%;
}

#projectsTitle{
    margin: 0px;
    padding-bottom: 50px;
    text-align: center;
    font-weight: bolder;
    font-size: 50px;
    color: #FA4952;
}

.projectsPanels{
    gap: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.project-field{
    display: none;
}

.project-field:hover{
    display: none;
}

.logo-picture:hover {
    cursor: pointer;
}

.projectsPanels a{
    color: black;
    text-decoration: none;
}

.projectsPanels a:hover{
    color: black;
    transition: 0.2s;
}




</style>
