<template>
    <div class="jobfair">

        <img src="../../assets/ilustrations/hramR.png" alt="sasChurch" id="sasChurch">
        <div class="intPaneli">
                <SmallCard title="Naslov neke vesti!" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no" 
                    more="Pročitaj više" link="https://www.google.com" imageName="conference.png"/>
                <SmallCard title="Naslov neke vesti!" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no" 
                    more="Pročitaj više" link="https://www.google.com" imageName="conference.png"/>
                <SmallCard title="Naslov neke vesti!" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no" 
                    more="Pročitaj više" link="https://www.google.com" imageName="conference.png"/>
                <SmallCard title="Naslov neke vesti!" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no" 
                    more="Pročitaj više" link="https://www.google.com" imageName="conference.png"/>    
        </div>  
    </div>
</template>

<script>
import ProjectCard from "../ProjectCard.vue"
import SmallCard from "../SmallCard.vue"

export default {
  name: "Jobfair",
  components: {ProjectCard, SmallCard}
}
</script>

<style>

.jobfair{
    width: 85%;
    margin: 60px 7.5% 40px 7.5%;
}

.intPaneli{    
    /* margin: 80px auto;  */
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    gap: 80px;
}

</style>