<template>
    <div class="panels">
      <NumberPanel :count="22" label="Godina postojanja"/>
      <NumberPanel :count="300" label="Članova udruženja"/>
      <NumberPanel :count="120" label="Organizovanih događaja"/>
      <NumberPanel :count="100" label="Partnerskih kompanija"/>
    </div>
</template>

<style>
.panels{
    padding: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 70px;
}
</style>
<script>
import NumberPanel from "@/components/NumberPanel";

export default {
  components: {NumberPanel}
}
</script>